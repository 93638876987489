<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
@if (navigationItem) {
  <div
    class="menu-list-item"
    [class.expanded]="expanded"
    [class.compact]="(isCompact$ | async) && !isHover"
    (click)="onItemSelected(navigationItem!)"
    (mouseover)="isHovering = true"
    (focus)="isHovering = true"
    (mouseleave)="isHovering = false"
    (blur)="isHovering = false"
    [ngClass]="{
      'menu-list-item--active': navigationItem!.route ? router.isActive(navigationItem!.route, true) : false,
    }"
  >
    <a
      matRipple
      [routerLink]="navigationItem.route"
      matListItemTitle
      [matTooltip]="
        (isCompact$ | async) && (navigationItem.children?.length ?? 0) === 0 ? navigationItem.displayName : ''
      "
      matTooltipShowDelay="500"
      matTooltipPosition="right"
    >
      <div class="active-line"></div>
      <mat-icon [ngStyle]="{ 'padding-left': depth! * 12 + 'px' }" class="mat-list-icon" matListItemIcon>
        {{ navigationItem!.iconName }}
      </mat-icon>
      <div class="text-container">
        <span class="menu-item-text">{{ navigationItem!.displayName }}</span>
        @if (navigationItem!.children && navigationItem!.children.length) {
          <span class="twistie-container">
            <mat-icon class="menu-twistie" [class.open]="expanded"> expand_more </mat-icon>
          </span>
        }
      </div>
    </a>
    <!-- Hovering menu -->
    <div
      class="menu-hover"
      [class.visible]="!expanded && (isCompact$ | async) && (navigationItem.children?.length ?? 0) > 0 && isHovering"
    >
      <div class="hover-title">
        <p>{{ navigationItem.displayName }}</p>
      </div>
      @for (child of navigationItem!.children; track child) {
        <ingenix-navigation-item
          (menuItemSelected)="onTriggerSelected()"
          [navigationItem]="child"
          [depth]="depth! + 1"
        ></ingenix-navigation-item>
      }
    </div>
  </div>
}

@if ((navigationItem?.children?.length ?? 0) > 0) {
  <div class="menu-list-submenu" [ngClass]="expanded ? 'expanded' : ''">
    @for (child of navigationItem!.children; track child) {
      <ingenix-navigation-item
        [isCompact$]="isCompact$"
        (menuItemSelected)="onTriggerSelected()"
        [navigationItem]="child"
        [depth]="depth! + 1"
      ></ingenix-navigation-item>
    }
  </div>
}

import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { Observable, delay } from 'rxjs';
import NavigationItem from 'src/app/common/models/navigation-item.model';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  imports: [MatIconModule, MatListModule, RouterModule, MatTooltipModule, MatRippleModule, AsyncPipe, NgStyle, NgClass],
  selector: 'ingenix-navigation-item',
  standalone: true,
  styleUrls: ['./navigation-item.component.scss'],
  templateUrl: './navigation-item.component.html',
})
export class NavigationComponent implements OnInit {
  private _expanded: boolean = false;

  @HostBinding('attr.aria-expanded')
  public ariaExpanded = this.expanded;

  @Input()
  public depth: number | undefined;
  @Input()
  public isCompact$!: Observable<boolean>;
  @Input()
  public isHover: boolean = false;

  public isHovering: boolean = false;

  @Output() public menuItemSelected = new EventEmitter<boolean>();

  @Input()
  public navigationItem: NavigationItem | undefined;

  constructor(
    private navigationService: NavigationService,
    public router: Router,
  ) {
    if (this.depth === undefined) {
      this.depth = 1;
    }
  }

  public ngOnInit(): void {
    this.navigationService.currentUrl.pipe(delay(1)).subscribe((url: string) => {
      if (url) {
        this.expanded = false;

        if (this.navigationItem?.route?.length ?? 0 > 0) {
          this.expanded = url.indexOf(`${this.navigationItem!.route}`) > -1;
        } else {
          this.navigationItem?.children?.forEach(element => {
            if (!this.expanded) {
              this.expanded = url.indexOf(`${element.route}`) > -1;
            }
          });
        }
      }
      this.ariaExpanded = this.expanded;
    });
  }

  public onItemSelected(item: NavigationItem): void {
    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]);
    }

    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }

    this.onTriggerSelected();
  }

  public onTriggerSelected(): void {
    this.menuItemSelected.emit(true);
  }

  public get expanded(): boolean {
    return this._expanded;
  }

  @Input()
  public set expanded(value: boolean) {
    this._expanded = value;
  }
}

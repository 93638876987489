import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { Observable, filter, map, shareReplay, startWith } from 'rxjs';
import Breadcrumb from 'src/app/common/models/breadcrumb.model';

import { BreadcrumbItemComponent } from '../breadcrumb-item/breadcrumb-item.component';

@Component({
  imports: [CommonModule, RouterModule, BreadcrumbItemComponent],
  selector: 'ingenix-breadcrumb',
  standalone: true,
  styleUrls: ['./breadcrumb.component.scss'],
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs$?: Observable<Breadcrumb[]>;
  groupName$?: Observable<string>;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  private createBreadcrumbs(route: ActivatedRoute, url: string): Breadcrumb[] {
    const breadcrumbs: Breadcrumb[] = [];

    const children = route.children;
    for (const child of children) {
      const routeUrl = child.snapshot.url.map(segment => segment.path).join('/');

      if (routeUrl !== '') {
        url += `/${routeUrl}`;
      }

      const breadcrumbLabel = child.snapshot.data['breadcrumb'];
      if (typeof breadcrumbLabel === 'function') {
        breadcrumbs.push({
          label: breadcrumbLabel(child.snapshot.data),
          shouldBeTranslated: false,
          url: url !== '' ? url : '/',
        });
      } else if (breadcrumbLabel !== '' && breadcrumbLabel !== null && breadcrumbLabel !== undefined) {
        breadcrumbs.push({
          label: breadcrumbLabel,
          shouldBeTranslated: true,
          url: url !== '' ? url : '/',
        });
      }

      if (child.children.length > 0) {
        breadcrumbs.push(...this.createBreadcrumbs(child, url));
      }
    }

    return breadcrumbs;
  }

  public ngOnInit(): void {
    this.breadcrumbs$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      startWith(null),
      map(() => this.createBreadcrumbs(this.route.root, '')),
      shareReplay(1),
    );
  }
}

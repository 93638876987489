import { HttpHandlerFn, HttpRequest } from '@angular/common/http';

export function HeaderInterceptorFn(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const contentType = req.headers.get('Content-Type');
  const responseType = req.responseType;

  // Needed for some assets like svgIcons.
  if (req.url.startsWith('assets') || req.url.startsWith('./') || req.url.startsWith('../')) return next(req);

  if (contentType === 'multipart/form-data') {
    const headertsWithoutContentType = req.headers.delete('Content-Type');
    const apiReq = req.clone({
      headers: headertsWithoutContentType,
      responseType: responseType ?? 'json',
    });
    return next(apiReq);
  }

  const headers = req.headers.set('Content-Type', contentType ?? 'application/json');
  const apiReq = req.clone({
    headers: headers,
    responseType: responseType ?? 'json',
  });

  return next(apiReq);
}

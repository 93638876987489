import { Moment } from 'moment';

export interface ListArticleStockTransaction {
  articleId: number;
  createdOn: Moment;
  currentQuantity: number;
  id: number;
  modifiedOn?: Moment;
  previousQuantity?: number;
  reason: string;
  relation: string;
  type: StockTransactionType;
}

export enum StockTransactionType {
  Manual = 'Manual',
  Purchase = 'Purchase',
  Sale = 'Sale',
  Unknown = 'Unknown',
}

export interface CreateStockTransaction {
  currentQuantity: number;
  reason: string;
}

export interface StockTransactionModel {
  id: number;
}

export interface AddEditStockTransactionDialogData {
  articleId: number;
}

import { Injectable } from '@angular/core';

import ExceptionResponse from '../models/exception-response.model';
import { ErrorCodeTranslationService } from './error-code-translation.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  constructor(private _errorCodeTranslation: ErrorCodeTranslationService) {}

  public getErrorMessage(error: ExceptionResponse): string {
    return this._errorCodeTranslation.translateErrorCode(error.code, error.message);
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveLayoutService {
  public isMobile(): boolean {
    return window.innerWidth < 480;
  }
}

<ingenix-header [title]="'Stock overzicht'"> </ingenix-header>

<ingenix-article-table-filter
  (handleIsBaseArticleChanged)="onBaseArticleChanged($event)"
  [showArticleGroupFilter]="true"
  (handleArticleGroupChanged)="onArticleGroupChanged($event)"
  (handleSearchTermChanged)="onSearchTermChanged($event)"
></ingenix-article-table-filter>
<ingenix-article-table
  [displayedColumns]="displayedColumns"
  [isLoading]="isLoading"
  [forStock]="true"
  [replacementArticles]="(replacementArticles$ | async) ?? []"
  (expandArticleRow)="handleRowExpanded($event)"
  (pageChanged)="handlePageEvent($event)"
  (sortChanged)="handleSortEvent($event)"
  [totalCount]="(totalCount$ | async) ?? 0"
  [articles]="(articles$ | async) ?? []"
>
</ingenix-article-table>

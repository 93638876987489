import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import '@angular/common/locales/global/nl';
import { importProvidersFrom } from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { AuthConfig, HttpInterceptorConfig, authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';

import { autoServiceRoutes } from './app/auto-service.routing';
import { URLInterceptorFn } from './app/common/interceptors/base-url.interceptor';
import { HeaderInterceptorFn } from './app/common/interceptors/header.interceptor';
import { httpCatchErrorInterceptorFn } from './app/common/interceptors/http-catch-error.interceptor';
import { AutoServiceContainer } from './app/containers/auto-service/auto-service.container';
import { environment } from './environments/environment';

bootstrapApplication(AutoServiceContainer, {
  providers: [
    provideAnimations(),
    provideAuth0(<AuthConfig>{
      authorizationParams: {
        audience: environment.auth0.audience,
        redirect_uri: environment.auth0.redirectUri,
      },
      clientId: environment.auth0.clientId,
      domain: environment.auth0.domain,
      httpInterceptor: <HttpInterceptorConfig>{
        // Protect all endpoints executing a Post / Delete / Put / Patch
        allowedList: [`${environment.serverApiUrl}/*`],
      },
    }),
    provideRouter(autoServiceRoutes),
    provideHttpClient(
      withInterceptors([URLInterceptorFn, HeaderInterceptorFn, authHttpInterceptorFn, httpCatchErrorInterceptorFn]),
      withInterceptorsFromDi(),
    ),
    importProvidersFrom([BrowserModule, BrowserAnimationsModule]),
  ],
}).catch(err => console.log(err));

export const environment = {
  auth0: {
    audience: 'https://api-development.auto-service.be',
    clientId: 'qiFhdetxv7plg8n5VoRa26p9ZKGYta59',
    domain: 'auto-service-dev.eu.auth0.com',
    redirectUri: window.location.origin,
  },
  name: 'Auto Service App',
  production: false,
  serverApiUrl: 'https://auto-service-erp-api-dev.azurewebsites.net',
};

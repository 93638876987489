import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export function URLInterceptorFn(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const baseUrl = environment.serverApiUrl;

  if (req.url.startsWith('assets') || req.url.startsWith('./') || req.url.startsWith('../')) return next(req);

  const apiReq = req.url.startsWith('http') ? req.clone({ url: req.url }) : req.clone({ url: `${baseUrl}${req.url}` });

  return next(apiReq);
}

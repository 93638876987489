import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, of } from 'rxjs';

import GenericResponseModel from '../models/generic-response.model';
import { ErrorMessageService } from '../services/error-message.service';
import { SnackbarService } from '../services/snackbar.service';

export const httpCatchErrorInterceptorFn: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const _snackbarService = inject(SnackbarService);
  const _errorMessageService = inject(ErrorMessageService);
  return next(req).pipe(
    catchError(err => {
      const genericErrorMessage = err.error as GenericResponseModel;
      _snackbarService.showError(_errorMessageService.getErrorMessage(genericErrorMessage.exception));
      return of(err);
    }),
  );
};

import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  imports: [RouterModule],
  selector: 'ingenix-breadcrumb-item',
  standalone: true,
  styleUrls: ['./breadcrumb-item.component.scss'],
  templateUrl: './breadcrumb-item.component.html',
})
export class BreadcrumbItemComponent {
  @Input() public exactUrl = false;
  @Input() public label!: string;
  @Input() public link!: string;
}

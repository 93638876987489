import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorCodeTranslationService {
  public translateErrorCode(errorCode: string, message?: string): string {
    switch (errorCode) {
      case '@Article.Create.BarcodeExists':
      case '@Article.Update.BarcodeExists':
        return 'Artikel met deze barcode bestaat al';
      case '@Article.Create.ModelValidationFailed':
      case '@Article.Update.ModelValidationFailed':
        return 'Model validatie gefaald.';
      case '@Relation.ValidateVat.CountryNotExists':
        return 'Geen land gevonden voor deze relatie, maak een factuuradres aan';
      case '@Relation.ValidateVat.VatNumberNotValid':
        return 'BTW-nummer niet geldig, dit kan fouten geven in facturatie';
      case '@Purchase.Calculate.RelationNotExists':
        return 'De gekozen leverancier levert dit artikel niet';
      case '@Article.Delete.ArticleHasTransactions':
        return 'Artikel heeft reeds transacties, kan deze dus niet verwijderen';
      case '@Article.Duplicate.CodeAlreadyExists':
        return 'Artikel met deze code bestaat reeds.';
      case '@Article.UpdateBaseArticle.ArticleStockNotTracked':
      case '@Article.Create.ArticleStockNotTracked':
      case '@Article.Update.ArticleStockNotTracked':
        return 'Niet beheerde artikels kunnen geen artikels met lus bevatten';
      default:
        return `Fout: ${errorCode} ${message}`;
    }
  }
}

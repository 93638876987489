import { AsyncPipe, DOCUMENT, NgClass } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, Observable, Subject, map, shareReplay, takeUntil, tap } from 'rxjs';
import NavigationItem, { NavigationOptions } from 'src/app/common/models/navigation-item.model';
import { BreadcrumbComponent } from 'src/app/components/breadcrumb/breadcrumb.component';
import { NavigationComponent } from 'src/app/components/navigation-item/navigation-item.component';
import { NavigationService } from 'src/app/services/navigation.service';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';

@Component({
  imports: [
    MatSidenavModule,
    AsyncPipe,
    NgClass,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MatListModule,
    MatMenuModule,
    NavigationComponent,
    BreadcrumbComponent,
    MatToolbarModule,
  ],
  selector: 'ingenix-root',
  standalone: true,
  styleUrls: ['./auto-service.container.scss'],
  templateUrl: './auto-service.container.html',
})
export class AutoServiceContainer implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private navigationOptions: NavigationOptions;

  public isCompactSideBar$: Observable<boolean>;

  public isModuleLoading: boolean = false;

  public mode: MatDrawerMode = 'over';
  public navOptions$ = new BehaviorSubject<NavigationOptions>({ compactSideBar: false, opened: true } as NavigationOptions);

  public navigation$?: Observable<NavigationItem[]>;
  public opened$: Observable<boolean>;
  public showMobileBackdrop: boolean | undefined;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private _navigationService: NavigationService,
    public authService: AuthService,
    private _responsiveLayoutService: ResponsiveLayoutService,
  ) {
    this.updateMenu();
    this.navigationOptions = { compactSideBar: false, opened: true } as NavigationOptions;

    this.opened$ = this.navOptions$.pipe(
      shareReplay(1),
      map(x => x.opened),
    );
    this.isCompactSideBar$ = this.navOptions$.pipe(
      shareReplay(1),
      map(x => x.compactSideBar),
      tap(isCompact => {
        // If this is done in CSS the drawer content does not resize properly.
        const element = document.querySelector('.mat-drawer-content') as HTMLElement;
        element.style.marginLeft = isCompact ? '80px' : '220px';
      }),
    );

    this.navOptions$.next(this.navigationOptions);
  }
  private changeNavOptions(navOptions: NavigationOptions): void {
    this.navOptions$.next(navOptions);
  }
  private isMobile(): boolean {
    return window.innerWidth < 600;
  }

  public identify(index: number): number {
    return index;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngOnInit(): void {
    this.navigation$ = this._navigationService.getNavigation().pipe(takeUntil(this.destroy$));
  }

  public onClickBackdrop(): void {
    this.navigationOptions.opened = false;
    this.changeNavOptions(this.navigationOptions);
  }

  public onItemClicked(event: boolean): void {
    if (this.isMobile()) {
      this.navigationOptions.opened = event;
      this.changeNavOptions(this.navigationOptions);
    }
  }

  public toggleCompactSidebar(newValue: boolean): void {
    this.navigationOptions.compactSideBar = newValue;
    this.changeNavOptions(this.navigationOptions);
  }

  public toggleSidenav() {
    this.navigationOptions.opened = !this.navigationOptions.opened;
    this.changeNavOptions(this.navigationOptions);
  }

  @HostListener('window:resize', [])
  public updateMenu(): void {
    if (!this.isMobile()) {
      this.mode = 'side';
    } else {
      this.mode = 'over';
    }

    if (this.isMobile()) {
      this.navigationOptions.opened = true;
      this.changeNavOptions(this.navigationOptions);
    }
  }
}

import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { SortDirection as MaterialSortDirection, Sort } from '@angular/material/sort';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  combineLatest,
  map,
  of,
  shareReplay,
  switchMap,
  takeUntil,
} from 'rxjs';

import PagedResult from '../../../../app/common/models/paged-result.model';
import { SortDirection } from '../../../../app/common/models/sort-direction.enum';
import { HeaderComponent } from '../../../../app/components/header/header.component';
import { ArticleTableComponent } from '../../../shared/components/article-table/article-table.component';
import ListArticleModel from '../../../shared/models/article-overview.model';
import { ArticleTableFilterComponent } from '../../components/article-table-filter/article-table-filter.component';
import { ArticleService } from '../../services/article.service';

@Component({
  imports: [CommonModule, ArticleTableComponent, ArticleTableFilterComponent, HeaderComponent],
  selector: 'ingenix-article-stock-overview',
  standalone: true,
  styleUrl: './article-stock-overview.component.scss',
  templateUrl: './article-stock-overview.component.html',
})
export class ArticleStockOverviewComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();
  private pageSearchSort$ = new BehaviorSubject<{
    articleGroupId?: null | number;
    baseArticleId?: null | number;
    includeBaseArticle?: boolean | null;
    isBaseArticle?: boolean | null;
    page: PageEvent | null;
    searchTerm: null | string;
    sort: Sort | null;
  }>({
    isBaseArticle: undefined,
    page: null,
    searchTerm: null,
    sort: {
      active: 'Code',
      direction: 'asc',
    },
  });
  private readonly refresh$ = new BehaviorSubject<void>(void 0);
  public articles$?: Observable<ListArticleModel[]>;
  public displayedColumns: string[] = [
    'select',
    'code',
    'description',
    'exclusiveVatPrice',
    'webshopPrice',
    'location',
    'stockQuantity',
    'orderedQuantity',
    'backOrderQuantity',
    'isBaseArticle',
  ];

  public isLoading = signal<boolean>(false);
  public replacementArticles$?: Observable<ListArticleModel[]>;
  public totalCount$?: Observable<number>;
  constructor(private articleService: ArticleService) {}
  private getSortDirection(sortDirection?: MaterialSortDirection | undefined): SortDirection {
    if (sortDirection === 'asc') {
      return SortDirection.Ascending;
    }

    return SortDirection.Descending;
  }

  public handlePageEvent(page: PageEvent): void {
    this.pageSearchSort$.next({
      articleGroupId: this.pageSearchSort$.value.articleGroupId,
      baseArticleId: this.pageSearchSort$.value.baseArticleId,
      includeBaseArticle: this.pageSearchSort$.value.includeBaseArticle,
      isBaseArticle: this.pageSearchSort$.value.isBaseArticle,
      page: page,
      searchTerm: this.pageSearchSort$.value.searchTerm,
      sort: this.pageSearchSort$.value.sort,
    });
  }

  public handleRowExpanded(row?: ListArticleModel): void {
    if (!row) {
      this.replacementArticles$ = of([]);
      return;
    }

    this.replacementArticles$ = this.articleService
      .searchArticles(
        0,
        100,
        'code',
        SortDirection.Ascending,
        undefined,
        undefined,
        undefined,
        undefined,
        row?.id ?? undefined,
        false,
      )
      .pipe(
        map(result => {
          return result.data;
        }),
      );
  }

  public handleSortEvent(sort: Sort): void {
    this.pageSearchSort$.next({
      articleGroupId: this.pageSearchSort$.value.articleGroupId,
      baseArticleId: this.pageSearchSort$.value.baseArticleId,
      includeBaseArticle: this.pageSearchSort$.value.includeBaseArticle,
      isBaseArticle: this.pageSearchSort$.value.isBaseArticle,
      page: this.pageSearchSort$.value.page,
      searchTerm: this.pageSearchSort$.value.searchTerm,
      sort: sort,
    });
  }

  public ngOnInit(): void {
    const searchResult$ = combineLatest([this.pageSearchSort$, this.refresh$]).pipe(
      switchMap(([pageSearchSort]) => {
        this.isLoading.update(() => true);
        return this.articleService
          .searchArticles(
            pageSearchSort.page?.pageIndex ?? 0,
            pageSearchSort.page?.pageSize ?? 10,
            pageSearchSort.sort?.active,
            this.getSortDirection(pageSearchSort.sort?.direction),
            pageSearchSort.searchTerm ?? undefined,
            pageSearchSort.isBaseArticle ?? undefined,
            undefined,
            pageSearchSort.articleGroupId ?? undefined,
            pageSearchSort.baseArticleId ?? undefined,
            pageSearchSort.includeBaseArticle ?? undefined,
          )
          .pipe(catchError(() => of({ data: [], totalCount: 0 } as PagedResult<ListArticleModel>)));
      }),
      shareReplay(1),
    );

    this.totalCount$ = searchResult$.pipe(
      map(result => {
        return result.totalCount;
      }),
    );

    this.articles$ = searchResult$.pipe(
      takeUntil(this.destroy$),
      map(result => {
        this.isLoading.update(() => false);
        return result.data;
      }),
    );
  }

  public onArticleGroupChanged(id: null | number) {
    this.pageSearchSort$.next({
      articleGroupId: id,
      baseArticleId: this.pageSearchSort$.value.baseArticleId,
      includeBaseArticle: this.pageSearchSort$.value.includeBaseArticle,
      isBaseArticle: this.pageSearchSort$.value.isBaseArticle,
      page: this.pageSearchSort$.value.page,
      searchTerm: this.pageSearchSort$.value.searchTerm,
      sort: this.pageSearchSort$.value.sort,
    });
  }

  public onBaseArticleChanged(isBaseArticle: boolean | null): void {
    this.pageSearchSort$.next({
      articleGroupId: this.pageSearchSort$.value.articleGroupId,
      baseArticleId: this.pageSearchSort$.value.baseArticleId,
      includeBaseArticle: this.pageSearchSort$.value.includeBaseArticle,
      isBaseArticle: isBaseArticle,
      page: this.pageSearchSort$.value.page,
      searchTerm: this.pageSearchSort$.value.searchTerm,
      sort: this.pageSearchSort$.value.sort,
    });
  }

  public onSearchTermChanged(searchTerm: null | string): void {
    this.pageSearchSort$.next({
      articleGroupId: this.pageSearchSort$.value.articleGroupId,
      baseArticleId: this.pageSearchSort$.value.baseArticleId,
      includeBaseArticle: this.pageSearchSort$.value.includeBaseArticle,
      isBaseArticle: this.pageSearchSort$.value.isBaseArticle,
      page: this.pageSearchSort$.value.page,
      searchTerm: searchTerm,
      sort: this.pageSearchSort$.value.sort,
    });
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorCodeTranslationService {
  public translateErrorCode(errorCode: string, message?: string): string {
    switch (errorCode) {
      case '@Article.Create.BarcodeExists':
      case '@Article.Update.BarcodeExists':
        return 'Artikel met deze barcode bestaat al';
      case '@Article.Create.ModelValidationFailed':
      case '@Article.Update.ModelValidationFailed':
        return 'Model validatie gefaald.';
      case '@Relation.ValidateVat.CountryNotExists':
        return 'Geen land gevonden voor deze relatie, maak een factuuradres aan';
      case '@Relation.ValidateVat.VatNumberNotValid':
        return 'BTW-nummer niet geldig, dit kan fouten geven in facturatie';
      default:
        return `Fout: ${errorCode} ${message}`;
    }
  }
}

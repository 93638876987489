<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<mat-sidenav-container>
  <mat-sidenav [class.compact]="isCompactSideBar$ | async" #sidenav [mode]="mode" [opened]="opened$ | async">
    <section class="branding">
      @if ((isCompactSideBar$ | async) === false) {
        <img class="app-logo" src="../../../assets/logo/logo.svg" alt="Auto service logo" [routerLink]="''" />
      }
      @if (isCompactSideBar$ | async) {
        <img class="app-logo" src="../../../assets/logo/logo.svg" alt="Auto service logo" [routerLink]="''" />
      }
      <span style="margin: auto"></span>
    </section>
    <mat-nav-list>
      @for (navigationItem of navigation$ | async; track identify($index)) {
        <ingenix-navigation-item
          [isCompact$]="isCompactSideBar$"
          (menuItemSelected)="onItemClicked($event)"
          [navigationItem]="navigationItem"
        ></ingenix-navigation-item>
      }
    </mat-nav-list>

    <div class="bottom">
      @if ((isCompactSideBar$ | async) === true) {
        <button mat-icon-button class="compact-toggle expand" (click)="toggleCompactSidebar(false)">
          <mat-icon color="primary">keyboard_double_arrow_right</mat-icon>
        </button>
      }
      @if ((isCompactSideBar$ | async) === false) {
        <button mat-icon-button class="compact-toggle" (click)="toggleCompactSidebar(true)">
          <mat-icon color="primary">keyboard_double_arrow_left</mat-icon>
        </button>
      }
    </div>
  </mat-sidenav>
  <mat-sidenav-content [class.compact]="isCompactSideBar$ | async">
    <mat-toolbar class="toolbar">
      <div class="toolbar-content">
        <div class="breadcrumb-container">
          <button mat-icon-button (click)="toggleSidenav()" class="menu-button">
            <mat-icon [class.hidden]="(opened$ | async) === true" class="icon-transition"> menu </mat-icon>
            <mat-icon [class.hidden]="(opened$ | async) === false" class="icon-transition icon-2"> menu_open </mat-icon>
          </button>

          <ingenix-breadcrumb></ingenix-breadcrumb>

          <ng-template #iconTemplate>
            <mat-icon>chevron_right</mat-icon>
          </ng-template>
        </div>

        @if (authService.user$ | async; as user) {
          <button class="user-img" mat-icon-button [matMenuTriggerFor]="accountMenu">
            <img src="{{ user.picture }}" alt="user-picture" />
          </button>

          <mat-menu #accountMenu="matMenu">
            <button mat-menu-item>
              <mat-icon>person</mat-icon>
              <span>{{ user?.name }}</span>
            </button>
            <button mat-menu-item (click)="authService.logout()">
              <mat-icon>exit_to_app</mat-icon>
              <span>Log out</span>
            </button>
          </mat-menu>
        }
      </div>
    </mat-toolbar>
    <div class="sidebar-backdrop" matRipple [class.visible]="showMobileBackdrop" (click)="onClickBackdrop()"></div>
    <main class="container-main-content">
      <router-outlet></router-outlet>
      @if (isModuleLoading) {
        <div class="view-loader" style="position: fixed" fxLayout="column" fxLayoutAlign="center center">
          <div class="spinner">
            <div class="double-bounce1 mat-bg-accent"></div>
            <div class="double-bounce2 mat-bg-primary"></div>
          </div>
        </div>
      }
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
